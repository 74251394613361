var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var rL,xga,vL,AL;
$CLJS.pL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Iu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.qL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.fo(a));};rL=function(a){return $CLJS.OH(a,$CLJS.dl)||$CLJS.OH(a,$CLJS.Qj)};
$CLJS.sL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);xga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.tL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.uL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
vL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.wL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.xL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.yL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.zL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);AL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.jL($CLJS.uL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.k(null,1,[$CLJS.ml,"non-blank string"],null),$CLJS.$f($CLJS.OF)],null)],null));$CLJS.jL($CLJS.xL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.St,new $CLJS.k(null,1,[$CLJS.qk,0],null)],null));$CLJS.jL($CLJS.sL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.St,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null));
$CLJS.jL(AL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ql,new $CLJS.k(null,2,[$CLJS.qk,36,$CLJS.$l,36],null)],null));$CLJS.jL(vL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.k(null,2,[$CLJS.ml,"valid semantic type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.sl);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),rL],null));
$CLJS.jL($CLJS.zL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.k(null,2,[$CLJS.ml,"valid base type",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.sl);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.OH(a,$CLJS.pl)&&!rL(a)}],null));
$CLJS.jL($CLJS.yL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HG,AL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.zL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.zL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ml,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,
vL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.uL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.uL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.uL],null)],null)],null));
$CLJS.jL(xga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Zl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,$CLJS.tL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,$CLJS.Ql,$CLJS.Fk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ok,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.yL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ol,$CLJS.Wt],null)],null)],null));